<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div class="pl-5 pr-5">
      <router-link :to="{ name: 'TestBuilderForm' }">
        <v-btn
          outlined
          color="info"
          class="mb-5"
          x-small
          large
          v-if="canAccess('test-create')"
        >
          <v-icon>mdi-book-plus</v-icon>
          <span class="font-weight-bold"> Tạo bài học / bài kiểm tra </span>
        </v-btn>
      </router-link>
      <div v-if="!is_call_api">
        <v-row>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#1479F4"
              style="float: left"
              v-model="data_pagination_test.current_page"
              :length="data_pagination_test.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-6"
              style="float: right; width: 45%"
              placeholder="Tìm kiếm theo tên less/test"
              v-model="search_input"
              @keyup.enter="enterSearchTest()"
            ></b-form-input>
            <span
              style="clear: both; display: inline-block; margin-left: 55%"
              class="text-subtitle-1 grey--text darken-1"
              >(Enter để tìm kiếm)</span
            >
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tên bài học / bài kiểm tra</th>
                    <th scope="col">Giới hạn thời gian</th>
                    <th scope="col">Thời lượng</th>
                    <th scope="col">Công cụ</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(test, i) in tests">
                    <tr :key="i">
                      <th scope="row" style="vertical-align: middle">
                        {{ i + 1 }}
                      </th>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ test.title }} (#{{ test.id }} )</span
                        >
                      </td>
                      <td>
                        <span
                          class="label label-lg label-inline font-weight-bold py-4"
                          v-bind:class="
                            test.is_limit_time == 1
                              ? 'label-light-success'
                              : 'label-light-warning'
                          "
                        >
                          {{ test.is_limit_time == 1 ? "Có" : "Không" }}
                        </span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          test.duration
                        }}</span>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-primary btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="previewLessTest(test.id)"
                                v-if="canAccess('test-create')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-file-find</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Xem nội dung</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'EditTest',
                                  params: { id: test.id },
                                }"
                                target="_blank"
                              >
                                <!-- <v-btn fab x-small color="#1c5580" v-bind="attrs" v-on="on"><v-icon color="white">mdi-square-edit-outline</v-icon></v-btn> -->
                                <button
                                  class="btn btn-icon btn-light-success btn-sm ml-4"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="canAccess('test-update')"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-square-edit-outline</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Sửa nội dung</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                v-if="canAccess('test-create')"
                                @click="openDialogDuplicateTest(test)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-content-copy</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Sao chép</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <!-- <v-btn fab x-small color="#1c5580" v-bind="attrs" v-on="on" class="ml-4" @click="deleteTest(test.id)"><v-icon color="white">mdi-delete</v-icon></v-btn> -->
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteTest(test)"
                                v-if="canAccess('test-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Xóa</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!tests.length">
                    <tr>
                      <td style="text-align: center" colspan="6">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#1479F4"
              v-model="data_pagination_test.current_page"
              :length="data_pagination_test.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogPreviewTest" scrollable>
        <v-card>
          <v-card-title class="headline grey lighten-2">
            <v-col cols="11">
              <span class="headline">Nội dung bài học / bài kiểm tra</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogPreviewTest = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text class="dialog-preview-test">
            <!-- <PreviewLessTest :dataTest="object_test"></PreviewLessTest> -->
            <!-- <b-embed type="iframe" aspect="16by9" :src="location_origin+'/preview-test/dist/index.html?test_id='+preview_test_id" scrolling="yes"></b-embed> -->
            <iframe
              :src="
                location_origin +
                '/preview-test/dist/index.html?test_id=' +
                preview_test_id
              "
              style="height: 100%; width: 100%"
              frameborder="0"
            ></iframe>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogPreviewTest = false"
              >Đóng</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="dialogDuplicateTest"
        scrollable
        persistent
        max-width="1000px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sao chép bài test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogDuplicateTest = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px"
                    >Tiêu đề (
                    <span
                      style="font-size: 12px"
                      class="light-blue--text text--lighten-1"
                      >bài test muốn sao chép</span
                    >
                    )</label
                  >
                  <v-text-field
                    outlined
                    placeholder="Enter text ..."
                    v-model="title_test_input"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="btnDuplicateTest"
              >Save</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="dialogDuplicateTest = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import _ from "lodash";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "TestList",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: ["is_list_draft"],
  data() {
    return {
      is_call_api: false,
      tests: [],
      search_input: "",
      data_pagination_test: {
        current_page: 1,
        total_pages: 1,
      },
      object_test: [],
      dialogPreviewTest: false,
      preview_test_id: null,
      dialogDuplicateTest: false,
      test_id: null,
      title_test_input: "",
      test: {},
    };
  },
  computed: {
    location_origin: {
      get() {
        return window.location.origin;
      },
    },
  },
  created() {
    this.setDataTest();
  },
  watch: {
    is_list_draft: function (val) {
      if (val == 0) {
        this.getAllTest();
      } else {
        this.getAllTestDraft();
      }
    },
    "data_pagination_test.current_page": function (page) {
      if (this.is_list_draft == 0) {
        this.getAllTest(page, this.checkQuerySearch(this.search_input));
      } else {
        this.getAllTestDraft(page, this.checkQuerySearch(this.search_input));
      }
    },
    // search_input: function(val) {
    //   this.actionSearchTest(val);
    // }
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    setDataTest() {
      if (this.is_list_draft == 0) {
        this.getAllTest();
      } else {
        this.getAllTestDraft();
      }
    },
    getAllTest(page = 1, query = "") {
      let self = this;
      this.is_call_api = true;
      ApiService.get("prep-app/test?page=" + page + "&name=" + query).then(
        function (response) {
          if (response.status == 200) {
            self.is_call_api = false;
            self.tests = response.data.data;
            self.data_pagination_test = response.data.meta.pagination;
          }
        }
      );
    },
    getAllTestDraft(page = 1, query = "") {
      let self = this;
      this.is_call_api = true;
      ApiService.get(
        "prep-app/test/draft/list?page=" + page + "&name=" + query
      ).then(function (response) {
        if (response.status == 200) {
          self.is_call_api = false;
          self.tests = response.data.data;
          self.data_pagination_test = response.data.meta.pagination;
        }
      });
    },
    deleteTest(test) {
      let vm = this;
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa bài học / bài test này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          console.log(test);
          let data = {
            product_category_id: test.product_category_id,
          };
          ApiService.delete("prep-app/test/" + test.id, data)
            .then(function (res) {
              if (res.status == 200) {
                if (res.data.is_exists_course == 1) {
                  vm.$toasted.info(
                    "Bài học / bài kiểm tra đang nằm trong khóa học nên không được phép xóa",
                    {
                      theme: "toasted-primary",
                      position: "top-right",
                      duration: 4000,
                    }
                  );
                  return;
                }
                vm.setDataTest();
              }
            })
            .catch(function (error) {
              if (error) {
                if ([422].includes(error.response.status) ) {
                  vm.messError(error.response.data.message);
                } else {
                  vm.messError('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
                }
              }
            });
        }
      });
    },
    actionSearchTest: _.debounce(function (query) {
      if (this.is_list_draft == 0) {
        this.getAllTest(1, query);
      } else {
        this.getAllTestDraft(1, query);
      }
    }, 1200),
    async previewLessTest(id_test) {
      //console.log(window.scrollbars);
      this.preview_test_id = id_test;
      this.dialogPreviewTest = true;
    },
    openDialogDuplicateTest(test) {
      this.test_id = test.id;
      this.title_test_input = test.title + " (Copy)";
      this.test = JSON.parse(JSON.stringify(test));
      this.dialogDuplicateTest = true;
    },
    async btnDuplicateTest() {
      if (this.title_test_input == null || this.title_test_input == "") {
        this.$toasted.error("Tiêu đề không được để trống", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      let vm = this;
      let data = {
        title: this.title_test_input,
        product_category_id: this.test.product_category_id,
      };
      this.is_call_api = true;
      await ApiService.post(
        "prep-app/test/" + this.test_id + "/duplicate",
        data
      )
        .then(function (res) {
          if (res.status == 200) {
            Swal.fire({
              title: "",
              text: "Duplicate successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            vm.dialogDuplicateTest = false;
            vm.is_call_api = false;
            if (vm.is_list_draft == 0) {
              vm.getAllTest();
            } else {
              vm.getAllTestDraft();
            }
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error) {
            if ([422].includes(error.response.status) ) {
              vm.messError(error.response.data.message);
            } else {
              vm.messError('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
            }
          }
        });
    },
    enterSearchTest() {
      if (this.is_list_draft == 0) {
        this.getAllTest(1, this.checkQuerySearch(this.search_input));
      } else {
        this.getAllTestDraft(1, this.checkQuerySearch(this.search_input));
      }
    },
    checkQuerySearch(query) {
      if (query.substring(0, 1) === "#") {
        return "id-" + query.substring(1);
      }
      return query;
    },
    messError(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}

.label.label-light-success {
  color: #1479f4;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #1479f4;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #1479f4;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #1479f4;
}
.dialog-preview-test {
  overflow: hidden !important;
  height: 10000px !important;
}
</style>
